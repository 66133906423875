import {flow, makeObservable, observable} from "mobx"
import axios from '../communication/rest'

export default class TradeListStore {

  trades = []

  page = null

  pageable = {}

  query = {}

  constructor() {
    makeObservable(this, {
      trades: observable,
      list: flow,
      changePage: flow,
      search: flow,
      deleteTradeById: flow,
      query: observable
    })
  }

  *deleteTradeById(tradeId) {
    yield axios.delete(`trades/${tradeId}`);
    this.trades = this.trades.filter(e => e.id !== tradeId)
  }

  *list() {
    const response = yield axios.get("trades", {
      params : {
        ...this.pageable,
        ...this.query
      }
    });
    this.trades = response.data.content
    delete response.data.content
    this.page = response.data
  }

  *changePage(pageable) {
    this.pageable = pageable;
    yield this.list();
  }

  *search(query) {
    this.query = query;
    yield this.list();
  }

}