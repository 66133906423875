import {useEffect} from "react";
import {inject, observer} from "mobx-react"
import {toJS} from "mobx";
import {useNavigate} from "react-router-dom";
import Table from "../components/Table";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {Box, Grid, IconButton, Tooltip, Typography} from "@mui/material";
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";
import CommentIcon from '@mui/icons-material/Comment';
import CopyableDate from "../components/CopyableDate";
import Pagination from "../components/Pagination";
import TradeQueryForm from "./TradeQueryForm";
import TradingviewLink from "../components/TradingviewLink";
import TagChips from "../components/TagChips";
import * as Numbers from "../util/Numbers";


export default inject(["tradeListStore"])(observer(({tradeListStore, ...props}) => {

  const navigate = useNavigate();

  useEffect(() => {
    tradeListStore.list()
  }, []);

  const deleteTrade = (tradeId) => (e) => {
    e.stopPropagation()
    tradeListStore.deleteTradeById(tradeId);
  }

  const columns = [
    {
      field: 'symbol',
      headerName : "SYM",
      renderRowCell: (column, row) => {
        return <TradingviewLink symbol={row[column.field]} dateToCopy={row['openingDate']} />
      }
    },
    {
      headerName : "",
      renderRowCell: (column, row, rowIndex) => {
        return <>
          {row.stopped && <FiberManualRecordOutlinedIcon color="warning" fontSize="inherit" />}
        </>
      }
    },
    {
      headerName : "",
      renderRowCell: (column, row) => {
        return <>
          {row.comment &&
            <Tooltip title={row.comment}>
              <CommentIcon fontSize="inherit" color="primary"/>
            </Tooltip>
          }
        </>
      }
    },
    {
      field: 'openingDate',
      headerName : "Opened",
      type : 'date',
      renderRowCell: (column, row) => {
        return <CopyableDate date={row[column.field]} />
      }
    },
    {
      field: 'closingDate',
      headerName : "Closed",
      type : 'date',
    },
    {
      field: 'direction',
      headerName : "Type",
    },
    {
      field: 'quantityMax',
      headerName : "Qty",
      type : 'integer',
    },
    {
      field: 'priceAvg',
      headerName : "Fill",
      type : 'number',
    },
    {
      field: 'riskRewardAmount',
      headerName : "Reward",
      type : 'money',
      attributes : ["colored"],
    },
    {
      field: 'riskRewardRatio',
      headerName : "R",
      type : 'number',
    },
    {
      headerCategory: "Risk",
      headerName : "absolute",
      renderRowCell: (column, row) => <>
          <Typography color={row.riskMax <= row.riskPreferredAmount ? 'success.main' : 'error.main'}>{Numbers.formatMoney(row.riskMax)}</Typography>
        </>
    },
    {
      headerCategory: "Risk",
      headerName : "preferred",
      renderRowCell: (column, row) => <>
        <Typography>{Numbers.formatMoney(row.riskPreferredAmount)}</Typography>
      </>
    },
    {
      headerCategory: "Risk to",
      field: 'riskMaxToAccount',
      headerName : "account",
      type : 'percent',
    },
    {
      headerCategory: "Risk to",
      field: 'riskMaxToTrade',
      headerName : "Trade",
      type : 'percent',
    },
    {
      field: '',
      headerName : "",
      align: 'right',
      renderRowCell: (column, row) => (
        <Box sx={{display: 'flex'}}>
          <div style={{position:'relative', height: '15px'}}>
            <IconButton size="small" onClick={deleteTrade(row.id)} style={{top: '-5px'}}>
              <DeleteOutlineIcon fontSize="inherit"/>
            </IconButton>
          </div>
        </Box>
      )
    },
    {
      headerName : "Tags",
      width: '100%',
      renderRowCell: (column, row) => <TagChips trade={row} />
    }
  ]

  const handleRowClick = (e) => {
    navigate(`${e.id}`)
  }

  const rows = toJS(tradeListStore.trades);
  const query = toJS(tradeListStore.query)

  return <Grid>
      <h2>Trades</h2>
      <TradeQueryForm query={query}/>
      <Pagination store={tradeListStore}/>
      <Table
        columns={columns}
        rows={rows}
        onRowClick={handleRowClick}
      />
    </Grid>
}))

